
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class supplementary_audit extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  t_all_data=[]

t_total= 0;
t_search_data = {
  results_name: ''

}

rule_t_search_data = {

}

t_detail_dialog = {
  open: false,
  title: '详情'
}

t_detail_data = {
  results_name: '',
  created_at: '',
  title: '',
  content: '',
  group_leader_name: '',
  group_leader_time: '',
  file: [] as any[],
  status: 0

}

t_edit_dialog = {
  open: false,
  title: '审核'
}

t_edit_data = {
  id: 0,
  status_: 0

}

rule_t_edit_data = {

}

status_1010 = new Map<any, string>([[1, '待审核'], [2, '通过'], [3, '拒绝']])
status__1011 = new Map<any, string>([[2, '通过'], [3, '拒绝']])

t_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    results_name: this.t_search_data.results_name

  }
  Api.http_supplementary_auditt0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_all_data = res.data.data
      this.t_total = res.data.total
    }
  })
}
t_detail_init(row) {

  Api.http_supplementary_audittget4({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_detail_data.results_name = res.data.results_name;
      this.t_detail_data.created_at = res.data.created_at;
      this.t_detail_data.title = res.data.title;
      this.t_detail_data.content = res.data.content;
      this.t_detail_data.group_leader_name = res.data.group_leader_name;
      this.t_detail_data.group_leader_time = res.data.group_leader_time;
      this.t_detail_data.file = res.data.file;
      this.t_detail_data.status = res.data.status;
      this.t_detail_dialog.open = true;

    }
  })

}
t_detail_cancle() {
  this.t_detail_dialog.open = false;
}
t_detail_ok() {
  this.t_detail_dialog.open = false;
}
t_edit_init(row) {

  Api.http_supplementary_audittget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit_data.id = row.id;
      this.t_edit_data.status_ = res.data.status_;
      this.t_edit_dialog.open = true;

    }
  })

}
t_edit_cancle() {
  this.t_edit_dialog.open = false;
}
t_edit_ok() {
  this.$refs.ref_t_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit_data.id,
        status_: this.t_edit_data.status_

      }
      Api.http_supplementary_auditt5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_init() {
  this.t_get_all_data(0, 10);

}
created() {
  this.t_init();

}

}
